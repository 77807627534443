<template>
  <b-flip-modal @close="close">
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="modal-header">
          <span class="com-title">Pixel Details</span>
        </div>
        <div class="modal-body">
          <div v-if="!loading && pixel.flip_id.length">
            <form>
              <div class="form-group">
                <label>Flip ID</label>
                <label class="value">{{ pixel.flip_id }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Name</label>
                <label class="value">{{ pixel.name }}</label>
              </div>
              <div class="form-group">
                <label>Xandr ID</label>
                <label class="value">{{ pixel.apn_adv_id }}</label>
              </div>
              <div class="form-group">
                <label>Xandr Name</label>
                <label class="value">{{ pixel.apn_name }}</label>
              </div>
              <div class="form-group">
                <label>Active</label>
                <label class="value">{{ pixel.active }}</label>
              </div>
              <div class="form-group">
                <label>Average Daily Pixel Fires</label>
                <label class="value">{{
                  formatVal(getPixelFireValue('dailyAverage'), '0,0.[00]')
                }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires in last 15 Minutes</label>
                <label class="value">{{
                  formatVal(getPixelFireValue('last15Minutes'), '0,0')
                }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (Yesterday)</label>
                <label class="value">{{ formatVal(getPixelFireValue('yesterday'), '0,0') }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (7 Days Avg.)</label>
                <label class="value">{{
                  formatVal(getPixelFireValue('last7DayAverage'), '0,0.[00]')
                }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (Yesterday Delta)</label>
                <label class="value">{{
                  formatVal(getPixelFireValue('yesterdayDelta'), '0,0.[00]')
                }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (7 Days)</label>
                <label class="value">{{ formatVal(getPixelFireValue('last7Days'), '0,0') }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (30 Days)</label>
                <label class="value">{{ formatVal(getPixelFireValue('last30Days'), '0,0') }}</label>
              </div>
              <div class="form-group">
                <label>Pixel Fires (Lifetime)</label>
                <label class="value">{{ formatVal(getPixelFireValue('lifetime'), '0,0') }}</label>
              </div>
              <div class="form-group" style="text-align: right; float:right; display: contents;">
                <div class="actions-btn">
                  <k-button :size="3" label="CLOSE" @click="close" />
                </div>
              </div>
            </form>
          </div>
          <b-loading-spinner v-else class="txt-center" />
        </div>
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import numeral from 'numeral';
import flipPixelApi from '~/api/flipPixel';

export default {
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  props: {
    initialPixel: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    advertiserInView: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    const pixel = JSON.parse(JSON.stringify(this.initialPixel));

    return {
      pixel,
      loading: false,
    };
  },
  async mounted() {
    await this.fetchPixelDetails();
  },
  methods: {
    getPixelFireValue(key) {
      if (!this.pixel.pixel_fires) {
        return 0;
      }
      return this.pixel.pixel_fires[key] || 0;
    },
    async fetchPixelDetails() {
      try {
        this.loading = true;
        const data = await flipPixelApi.viewFlipPixel([
          this.advertiserInView.id,
          this.pixel.flip_id,
        ]);
        this.pixel = data;
      } catch (err) {
        console.error('error loading pixel details ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        if (!this.pixel) {
          this.pixel = {};
        }
        if (!this.pixel.pixel_fires) {
          this.pixel.pixel_fires = {};
        }
        this.loading = false;
      }
    },
    formatVal(val, format) {
      const num = numeral(val);
      return num.format(format);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 15px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        a {
          color: #848890;
        }
        .box {
          svg {
            color: #848890;
            &:hover {
              color: var(--adminprimarycolor) !important;
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 50%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      text-align: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.display-only {
  display: inline-block;
  top: 5px;
  clear: none;
  margin-left: 0;
}
</style>
